/* eslint-disable no-continue */
function handleStrapiMenuPluginData({ data }) {
  const [menu] = data;
  const sidebar = { appName: 'Nome do aplicativo', appLogo: '', defaultOpen: true, items: {} };

  let index = 0;
  for (const { attributes: menuItem } of menu.attributes.items.data) {
    // O primeiro item dos menus retornados é o item de configuração da sidebar, os demais são itens de menu
    if (index === 0) {
      sidebar.appName = menuItem.title;
      sidebar.appLogo = menuItem.appLogo.data.attributes.url;
      sidebar.defaultOpen = menuItem.defaultOpen;

      index++;
      continue;
    }

    sidebar.items = {
      ...sidebar.items,
      [`MENU_${index}`]: {
        title: menuItem.title,
        href: menuItem.href,
        icon: `${menuItem.icon};${menuItem.pack}`,
      },
    };

    index++;
  }
  return sidebar;
}

export async function setupSidebarOptionsFromStrapi(apwConfig) {
  if (apwConfig.APW_SIDEBAR_OPTIONS_URL) {
    try {
      const result = await fetch(apwConfig.APW_SIDEBAR_OPTIONS_URL, {
        method: 'GET',
      });
      if (!result.ok) {
        throw new Error(result.statusText);
      }
      const responseData = await result.json();

      const handledSidebarItems = handleStrapiMenuPluginData(responseData);

      window.apwSidebarOptions = handledSidebarItems;
    } catch (e) {
      throw new Error(e);
    }
  }
}
