const getFromCookie = (cookieName, attr) => {
  const cookieArr = document.cookie.split(';');

  for (const element of cookieArr) {
    const cookiePair = element.split('=');
    if (cookieName === cookiePair[0].trim()) {
      const cookieData = decodeURIComponent(cookiePair[1]).slice(2);

      if (!attr) return cookieData;

      try {
        return JSON.parse(cookieData)[attr];
      } catch {
        return null;
      }
    }
  }
  return null;
};

export default getFromCookie;
