module.exports = new Promise((resolve, reject) => {
          if (!window.apw.APW_BUNDLE_APP_URL && !window.apw.APW_ROUTES) {
            reject(new Error("Neither APW_BUNDLE_APP_URL or APW_ROUTES was provided, your application won't work!"))
            return
          }
          if (!window.apw.APW_BUNDLE_APP_URL && !!window.apw.APW_ROUTES) {
            reject("APW_BUNDLE_APP_URL is missing, but APW_ROUTES is present, you may ignore this warning")
            return
          }
          const script = document.createElement('script')
          script.src = window.apw.APW_BUNDLE_APP_URL
          script.onload = () => {
            const scope = window?.apw?.APW_BUNDLE_APP_SCOPE ? window.apw.APW_BUNDLE_APP_SCOPE : "apwNgApp"
            resolve(window[scope])
          }
          script.onerror = () => {
            reject()
          }
          document.head.appendChild(script);
        })
        ;